<template>
  <div>
    <!-- Waarschuwing voor geselecteerde jaren 2020 en 2021 -->
    <div v-if="selectedYear === 2020 || selectedYear === 2021" class="alert alert-warning" role="alert">
      Let op: Grafieken zijn niet beschikbaar voor 2020 & 2021.
    </div>

    <!-- Slot om de inhoud van de component weer te geven -->
    <slot v-else></slot>
  </div>
</template>

<script>
export default {
  name: 'YearWarning',
  props: {
    selectedYear: {
      type: Number,
      required: true
    }
  }
}
</script>